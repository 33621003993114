import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClientModule } from '@angular/common/http';

import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
// import { NativeKeyboard } from '@awesome-cordova-plugins/native-keyboard/ngx';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    
    
  ],
  providers: [{ 
    provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    Keyboard
    // NativeKeyboard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
